

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
  url('./assets/lato.ttf') format("truetype");
  font-weight: normal;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.product-image{
  height: 50vh;
  width: 100%;
  object-fit: cover;
  border-radius: 7px;
  object-position: center top;
}

.product-title{
  color: black;
  font-family: "Haboro Contrast", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 25px;
}

.product-brand{
  color: black;
  padding: 0;
  margin: 0;
  font-size: 20px;
}

.product-card{
  background-color: white;
  border-radius: 7px;
  padding: 10px;
  margin-top: 20px;
  width: 20%;
  box-shadow: 0px 0px 24px 3px rgba(0,0,0,0.19);
}

@media only screen and (max-width: 700px) {
  .product-card{
    width: 60%;
  }
  .product-image{
    height: 30vh;
  }
}

.product-info{
  padding: 0 20px;
}
