

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
  url(/static/media/lato.0aa846ba.ttf) format("truetype");
  font-weight: normal;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.product-image{
  height: 50vh;
  width: 100%;
  object-fit: cover;
  border-radius: 7px;
  object-position: center top;
}

.product-title{
  color: black;
  font-family: "Haboro Contrast", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 25px;
}

.product-brand{
  color: black;
  padding: 0;
  margin: 0;
  font-size: 20px;
}

.product-card{
  background-color: white;
  border-radius: 7px;
  padding: 10px;
  margin-top: 20px;
  width: 20%;
  box-shadow: 0px 0px 24px 3px rgba(0,0,0,0.19);
}

@media only screen and (max-width: 700px) {
  .product-card{
    width: 60%;
  }
  .product-image{
    height: 30vh;
  }
}

.product-info{
  padding: 0 20px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
  margin-bottom: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff9f5;
  min-height: 100vh;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.downloadLogo {
  width: 80%;
  max-width: 350px;
}

h1 {
  color: #212121;
  font-family: "Haboro Contrast", sans-serif;
  margin: 0;
}

h4 {
  color: #212121;
  font-family: "Lato", sans-serif;
}

