.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
  margin-bottom: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff9f5;
  min-height: 100vh;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.downloadLogo {
  width: 80%;
  max-width: 350px;
}

h1 {
  color: #212121;
  font-family: "Haboro Contrast", sans-serif;
  margin: 0;
}

h4 {
  color: #212121;
  font-family: "Lato", sans-serif;
}
